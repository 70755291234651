export const APP_NAME = "Marusik";

export const TOKEN_KEY = "token";
export const USER_DATA_KEY = "user-data";
export const DELIVERY_METHOD_KEY = "delivery-method";

export const PICKUP_ADDRESS = "г Москва, 2-я улица Машиностроения, дом 17, строение 1";
export const PICKUP_ID = "1";
export const PICKUP_TITLE = "ПВЗ Марусик";

export const API_URL = import.meta.env.VITE_API_URL;

export const DADATA_URL = import.meta.env.VITE_DADATA_URL;
export const DADATA_TOKEN = import.meta.env.VITE_DADATA_TOKEN;

export const BOXBERRY_KEY = import.meta.env.VITE_BOXBERRY_KEY;

export const TG_BOT_URL = import.meta.env.VITE_TG_URL;

export const SENTRY_ENABLED = import.meta.env.SENTRY_ENABLED || false;
